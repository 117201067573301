import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState } from 'react';
import icon from "../images/icon.svg"
import logo from "../images/logo.svg"

const Header = ({ siteTitle }) => {
  const [movileNav, setMobileNav] = useState(false);
  return (
  <header style={{ background: '#2cbc9b'}} className="bb b--black-10 shadow-4 top-0 left-0 right-0 z-max">
    <div className="mw8 center">
      <nav className="flex justify-between bb b--white-10 relative">
        <Link className="link white-70 hover-white no-underline flex items-center pa3" to="/">
          <img alt="New Hope Pet Center Logo" className="dn db-ns" src={logo} style={{maxHeight:"70px"}}/>
          <img alt="New Hope Pet Center Logo" className="db dn-ns" src={icon} style={{maxHeight:"70px"}}/>
        </Link>

        <div className="dn flex-grow pa3 flex-l items-center f6 ttu tracked-tight fw6">
          <Link activeStyle={{borderColor:"white"}} className="bb b--transparent link dib white dim mr3 mr4-ns" to="/">Home</Link>
          <Link activeStyle={{borderColor:"white"}} className="bb b--transparent link dib white dim mr3 mr4-ns" to="/animal-grooming-and-bathing/">Grooming Services</Link>
          <Link activeStyle={{borderColor:"white"}} className="bb b--transparent link dib white dim mr3 mr4-ns" to="/premium-quality-food/">Premium Food</Link>
          <Link activeStyle={{borderColor:"white"}} className="bb b--transparent link dib white dim mr3 mr4-ns" to="/products-we-carry/">Products</Link>
          <Link activeStyle={{borderColor:"white"}} className="bb b--transparent link dib white dim mr3 mr4-ns" to="/our-history">History</Link>
        </div>

        {movileNav && <div className="pa3 f6 ttu tracked-tight fw8 fixed top-0 bottom-0 right-0 z-max bg-black pt5" id="mobileNav">
          <button onClick={() => setMobileNav(false)} className="bg-black white tc dib bn absolute right-1 top-2 pointer hover-black-90 hover-green pa0 ma0 bn br2">
            <i className="material-icons mw-100 f3 pa2 bn">menu</i>
          </button>

          <Link activeStyle={{color:"#2cbc9b"}} className="link db mv4 white hover-green" to="/">Home</Link>
          <Link activeStyle={{color:"#2cbc9b"}} className="link db mv4 white hover-green" to="/animal-grooming-and-bathing/">Grooming Services</Link>
          <Link activeStyle={{color:"#2cbc9b"}} className="link db mv4 white hover-green" to="/premium-quality-food/">Premium Food</Link>
          <Link activeStyle={{color:"#2cbc9b"}} className="link db mv4 white hover-green" to="/products-we-carry/">Products</Link>
          <Link activeStyle={{color:"#2cbc9b"}} className="link db mv4 white hover-green" to="/our-history">History</Link>
          <a className="link db mt5 white hover-green f5 br-pill ba b--white-50 bg-white-05 tc ph3 pv2 tracked f7 dt" href="/tel:2158622778">
            <span className="dtc v-mid"><i className="material-icons pr2 f6 ">phone</i></span>
            <span className="dtc v-mid">(215) 862-2778</span>
          </a>
        </div>}

        <button onClick={() => setMobileNav(true)} className="bg-black white tc db dn-l bn absolute right-1 top-2 pointer hover-black-90 hover-green pa0 ma0 bn br2">
          <i className="material-icons mw-100 f3 pa2 bn">menu</i>
        </button>
      </nav>

    </div>

  </header>
)
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
