import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

import Header from "./header"
import "./layout.css"

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Header siteTitle={data.site.siteMetadata.title} />
        <div className="">
          <main>{children}</main>
          <footer>

            <div className="bg-near-white pv5 ph2 tc">
              <a href="tel:2158622778" className="dt-ns db center cf bg-green white fw8 pa2 pa3-ns no-underline shadow-5 hover-bg-dark-green grow">
                <span className="dtc-ns db white dib ph2">Call Now</span>
                <span><i className="dtc-ns db material-icons pa2 f6">phone</i></span>
                <span className="dtc-ns db pa2 b">(215) 862-2778</span>
              </a>
            </div>

            <div className="bg-dark-gray">

              <div className="dt-l dt--fixed-l mw6 mw8-l center">
                <div className="dtc-l tl white pa2 pa3-ns mt0-ns">
                  <h3 className="tl pl1 pl2-ns ttu tracked-tight white fw8">Contact Us</h3>
                  <div className="db pa1 pa2-ns b">
                    <a href="tel:2158622778" className="link no-underline white hover-bg-white-10 hover-green pa2 br2 b">
                      <i className="material-icons pr2 f6">phone</i> (215) 862-2778
                    </a>
                  </div>
                  <div className="db pa1 pa2-ns b">Village of Logan Square Shopping Center</div>
                  <div className="db pa1 pa2-ns b">12 Village Row</div>
                  <div className="db pa1 pa2-ns b">New Hope, PA 18938</div>
                  <div className="db pa1 pa2-ns b">NHPC@comcast.net</div>

                  <h3 className="tl pl1 pl2-ns ttu tracked-tight white fw8 mt5">Our Hours</h3>
                  <div className="db pa1 pa2-ns b dt">
                    <span className="fw8 ttu tracked-tight pr3 f6 dtc v-mid">Weekdays:</span>
                    <span>9:00 - 6:00</span>
                  </div>
                  <div className="db pa1 pa2-ns b dt">
                    <span className="fw8 ttu tracked-tight pr3 f6 dtc v-mid">Saturday:</span>
                    <span>10:00 - 5:00</span>
                  </div>
                  <div className="db pa1 pa2-ns b dt">
                    <span className="fw8 ttu tracked-tight pr3 f6 dtc v-mid">Sunday:</span>
                    <span>Closed during July and August</span>
                  </div>
                </div>
                <div className="dtc-l tc pa2 pa3-ns mt5 mt0-ns">
                  <h3 className="tl pl1 pl2-ns ttu tracked-tight white fw8">Find us on Facebook</h3>
                  <iframe title="Facebook" src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fnewhopepetcenter%2F&amp;tabs&amp;width=340&amp;height=214&amp;small_header=false&amp;adapt_container_width=true&amp;hide_cover=false&amp;show_facepile=true&amp;appId=171678856253412" width="100%" height="214" className="bn overflow-hidden" scrolling="no" frameBorder="0" allowtransparency="true"></iframe>
                </div>
                <div className="dtc-l pa2 pa3-ns white mt5 mt0-ns">
                  <h3 className="tl pl1 pl2-ns ttu tracked-tight fw8">Join Our Newsletter</h3>
                  <p className="lh-copy pv3 pl1 pl2-ns">Please leave your email for occasional free coupons, deals, specials, and giveaways!</p>
                  <div id="mc_embed_signup">
                    <form action="https://newhopepetcenter.us10.list-manage.com/subscribe/post?u=d86d905a94893563414cb706e&amp;id=ee66ed9ca6" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate="">
                      <div id="mc_embed_signup_scroll">
                        <div className="mc-field-group">
                          <label htmlFor="mce-EMAIL" className="fw1">Email Address:</label>
                          <input type="email" name="EMAIL" className="db w-100 input-reset pa2 bn" id="mce-EMAIL" />
                        </div>
                        <div id="mce-responses" className="clear">
                          <div className="response" id="mce-error-response" style={{ display: 'none', visibility: 'hidden' }}></div>
                          <div className="response" id="mce-success-response" style={{ display: 'none', visibility: 'hidden' }}></div>
                        </div>
                        <div style={{ position: 'absolute', left: '-5000px' }}><input type="text" name="b_d86d905a94893563414cb706e_ee66ed9ca6" tabIndex="-1" /></div>
                        <div className="clear">
                          <input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" className="pa2 bg-blue ttu tracked white bn db w-100 hover-bg-dark-blue pointer" />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>



              <div className="f6 ttu pa4 tc">© {new Date().getFullYear()} - New Hope Pet Center</div>

            </div>

            <a href="tel:2158622778" className="br--top br--left br3 bg-green white fw8 fixed bottom-0 right-0 pa2 pa3-ns ph4-l no-underline shadow-4 hover-bg-dark-green grow dt">
              <span className="dtc v-mid"><i className="material-icons pr2 f3">phone</i></span>
              <span className="dtc v-mid f5 f4-l b">(215) 862-2778</span>
            </a>

          </footer>
        </div>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
